import { ElementRef, Injectable } from '@angular/core'
import { BehaviorSubject, Observer, tap } from 'rxjs'
import {
  BotGuildInvitedModel,
  GuildChannelModel,
  GuildChannelType,
  GuildEmojiModel,
  GuildRoleModel,
  ZergroshModuleConfiguration,
  ZergroshModuleStatusModel,
  ZergroshModuleType,
  ZergroshModuleTypeID
} from '../models/zergrosh-model'
import { ZergroshService, getZerGroshBackendUrl } from './zergrosh.service'
import { KoalaReplyModel } from '../models/koala-model'
import { HttpClient } from '@angular/common/http'
import { DiscordGuildModel } from '../models/discord-model'
import { ConfirmationService, MessageService } from 'primeng/api'
import { LoaderService } from './loader.service'
import { Router } from '@angular/router'
import { DashboardTab } from '../models/dashboard-model'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private activeServerId: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null)

  private activeServerModules: BehaviorSubject<ZergroshModuleStatusModel[]> =
    new BehaviorSubject<ZergroshModuleStatusModel[]>([])

  private activeServer: BehaviorSubject<DiscordGuildModel | null> =
    new BehaviorSubject<DiscordGuildModel | null>(null)

  private activeServerChannels: BehaviorSubject<GuildChannelModel[]> =
    new BehaviorSubject<GuildChannelModel[]>([])

  private activeServerRoles: BehaviorSubject<GuildRoleModel[]> =
    new BehaviorSubject<GuildRoleModel[]>([])

  private activeServerEmojis: BehaviorSubject<GuildEmojiModel[]> =
    new BehaviorSubject<GuildEmojiModel[]>([])

  private servers: BehaviorSubject<DiscordGuildModel[]> = new BehaviorSubject<
    DiscordGuildModel[]
  >([])

  private activeTab: BehaviorSubject<DashboardTab> =
    new BehaviorSubject<DashboardTab>('CONTROL_PANEL')

  private rightPaneRef: BehaviorSubject<ElementRef | undefined> =
    new BehaviorSubject<ElementRef | undefined>(undefined)

  private serversInviteStatus: BehaviorSubject<BotGuildInvitedModel[]> =
    new BehaviorSubject<BotGuildInvitedModel[]>([])

  private _isSaving = false

  private configurationsCache: Map<
    string,
    ZergroshModuleConfiguration<unknown>
  > = new Map()

  constructor(
    private zergrosh: ZergroshService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private loader: LoaderService,
    private router: Router,
    private message: MessageService,
    private translate: TranslateService
  ) {}

  setServersInviteStatus(servers: BotGuildInvitedModel[]) {
    this.serversInviteStatus.next(servers)
  }

  getServersInviteStatus() {
    return this.serversInviteStatus.value
  }

  isBotPresentInGuild(id: string) {
    return (
      this.getServersInviteStatus().find((m) => m.id === id)?.botAdded ?? false
    )
  }

  getLoggedUserRole(): UserRoleType {
    const server = this.getActiveServer()
    if (server === null) {
      return 'MEMBER'
    }

    if (server.owner) {
      return 'OWNER'
    }

    if ((parseInt(server.permissions) & 0x8) !== 0) {
      return 'ADMINISTRATOR'
    }

    const inviteStatus = this.serversInviteStatus
      .getValue()
      .find((g) => g.id === server.id)
    if (inviteStatus !== undefined) {
      if (inviteStatus.canManage) {
        return 'MANAGER'
      }
    }

    return 'MEMBER'
  }

  onServersInviteStatusChange(
    observerOrNext:
      | Partial<Observer<BotGuildInvitedModel[]>>
      | ((value: BotGuildInvitedModel[]) => void)
  ) {
    this.serversInviteStatus.subscribe(observerOrNext)
  }

  setActiveTab(tab: DashboardTab) {
    this.activeTab.next(tab)
  }

  getActiveTab() {
    return this.activeTab.value
  }

  onActiveTabChange(
    observerOrNext:
      | Partial<Observer<DashboardTab>>
      | ((value: DashboardTab) => void)
  ) {
    return this.activeTab.subscribe(observerOrNext)
  }

  setServers(servers: DiscordGuildModel[]) {
    this.servers.next(servers)
  }

  getServers() {
    return this.servers.value
  }

  onServersChange(
    observerOrNext:
      | Partial<Observer<DiscordGuildModel[]>>
      | ((value: DiscordGuildModel[]) => void)
  ) {
    return this.servers.subscribe(observerOrNext)
  }

  setActiveServer(server: DiscordGuildModel) {
    this.clearAllCachedConfiguration()
    this.activeServer.next(server)
  }

  getActiveServer() {
    return this.activeServer.getValue()
  }

  onActiveServerChange(
    observerOrNext:
      | Partial<Observer<DiscordGuildModel | null>>
      | ((value: DiscordGuildModel | null) => void)
  ) {
    return this.activeServer.subscribe(observerOrNext)
  }

  setActiveServerId(id: string) {
    this.activeServerId.next(id)
  }

  getActiveServerId() {
    return this.activeServerId.value
  }

  onActiveServerIdChange(
    observerOrNext:
      | Partial<Observer<string | null>>
      | ((value: string | null) => void)
  ) {
    return this.activeServerId.subscribe(observerOrNext)
  }

  setActiveServerModules(modules: ZergroshModuleStatusModel[]) {
    this.activeServerModules.next(modules)
  }

  getActiveServerModules() {
    return this.activeServerModules.value
  }

  fetchActiveServerModules() {
    return this.http
      .get<
        KoalaReplyModel<ZergroshModuleStatusModel[]>
      >(getZerGroshBackendUrl() + '/modules?idGuild=' + this.getActiveServerId())
      .pipe(
        tap((response) => {
          if (response.success) {
            this.setActiveServerModules(response.data)
          }
        })
      )
  }

  onActiveServerModulesChange(
    observerOrNext:
      | Partial<Observer<ZergroshModuleStatusModel[]>>
      | ((value: ZergroshModuleStatusModel[]) => void)
  ) {
    return this.activeServerModules.subscribe(observerOrNext)
  }

  isModuleEnabled(module: ZergroshModuleType) {
    return (
      this.getActiveServerModules().find(
        (m) => m.idModule === ZergroshModuleTypeID[module]
      )?.enabled ?? false
    )
  }

  setModuleEnabled(module: ZergroshModuleType, enabled: boolean) {
    const idServer = this.getActiveServerId()

    if (idServer === null) {
      throw new Error('id server not defined...')
    }

    return this.setModuleEnabledWithGuild(idServer, module, enabled)
  }

  setModuleEnabledWithGuild(
    idGuild: string,
    module: ZergroshModuleType,
    enabled: boolean
  ) {
    return this.zergrosh
      .updateGuildModuleConfiguration(idGuild, module, {
        enabled
      })
      .pipe(
        tap((response) => {
          if (response.success) {
            let modules = this.getActiveServerModules()
            const moduleInstance = modules.find(
              (m) => m.idModule === ZergroshModuleTypeID[module]
            )
            if (moduleInstance !== undefined) {
              moduleInstance.enabled = enabled
              modules = [
                ...modules.filter(
                  (m) => m.idModule !== ZergroshModuleTypeID[module]
                ),
                moduleInstance
              ]
            }
            this.activeServerModules.next(modules)
          }
        })
      )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigateToModule(module: ZergroshModuleType, event: MouseEvent) {
    this.navigateToModulePerform(module)
  }

  isSaving() {
    return this._isSaving
  }

  setSaving(saving: boolean) {
    this._isSaving = saving
  }

  private navigateToModulePerform(module: ZergroshModuleType) {
    const serverId = this.getActiveServerId()

    switch (module) {
      case 'WELCOME_AND_GOODBYE':
        this.router.navigate([
          'me',
          'server',
          serverId,
          'mod',
          'welcome-and-goodbye'
        ])
        break
      case 'WELCOME_CHANNEL':
        this.router.navigate([
          'me',
          'server',
          serverId,
          'mod',
          'welcome-channel'
        ])
        break
      case 'REACTION_ROLES':
        this.router.navigate([
          'me',
          'server',
          serverId,
          'mod',
          'reaction-roles'
        ])
        break
      case 'LEVELS':
        this.router.navigate(['me', 'server', serverId, 'mod', 'levels'])
        break
      case 'MUSIC':
        this.router.navigate(['me', 'server', serverId, 'mod', 'music'])
        break
      case 'BIRTHDAY':
        this.router.navigate(['me', 'server', serverId, 'mod', 'birthday'])
        break
      case 'TIKTOK':
        this.router.navigate(['me', 'server', serverId, 'mod', 'tiktok'])
        break
      case 'TWITCH':
        this.router.navigate(['me', 'server', serverId, 'mod', 'twitch'])
        break
      case 'COUNT':
        this.router.navigate(['me', 'server', serverId, 'mod', 'count'])
        break
      case 'STORY':
        this.router.navigate(['me', 'server', serverId, 'mod', 'story'])
        break
    }
  }

  setActiveServerChannels(channels: GuildChannelModel[]) {
    this.activeServerChannels.next(channels)
  }

  setActiveServerRoles(roles: GuildRoleModel[]) {
    this.activeServerRoles.next(roles)
  }

  setActiveServerEmojis(roles: GuildEmojiModel[]) {
    this.activeServerEmojis.next(roles)
  }

  getActiveServerChannels(filter: GuildChannelType[] = []) {
    if (filter.length === 0) {
      return this.activeServerChannels.value
    } else {
      return this.activeServerChannels.value.filter(
        (ch) => filter.find((f) => f === ch.type) !== undefined
      )
    }
  }

  getActiveServerRoles() {
    return this.activeServerRoles.value
  }

  getActiveServerEmojis() {
    return this.activeServerEmojis.value
  }

  getCachedConfiguration(module: ZergroshModuleType) {
    const id = ZergroshModuleTypeID[module]
    if (!this.configurationsCache.has(id)) return undefined
    return JSON.parse(JSON.stringify(this.configurationsCache.get(id)))
  }

  setCachedConfiguration(
    module: ZergroshModuleType,
    configuration: ZergroshModuleConfiguration<unknown>
  ) {
    const id = ZergroshModuleTypeID[module]
    this.configurationsCache.set(id, JSON.parse(JSON.stringify(configuration)))
  }

  clearAllCachedConfiguration() {
    this.configurationsCache.clear()
  }

  clearCachedConfiguration(module: ZergroshModuleType) {
    const id = ZergroshModuleTypeID[module]
    if (!this.configurationsCache.has(id)) return
    this.configurationsCache.delete(id)
  }

  setRightPane(ref: ElementRef | undefined) {
    this.rightPaneRef.next(ref)
  }

  getRightPane() {
    return this.rightPaneRef.getValue()
  }
}

export type UserRoleType = 'MEMBER' | 'ADMINISTRATOR' | 'MANAGER' | 'OWNER'
